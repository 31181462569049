import { ComputedRef } from '@nuxtjs/composition-api';
import { Route } from 'vue-router';

const canonicalsMapping = {
  '/it-it/saldi': '/it-it/saldi-ufficiali',
  '/ue-it/saldi': '/ue-it/saldi-ufficiali',
  '/eue-it/saldi': '/eue-it/saldi-ufficiali',
  '/uee-it/saldi': '/uee-it/saldi-ufficiali',
  '/it-en/sale': '/it-en/main-sale',
  '/ue-en/sale': '/ue-en/main-sale',
  '/eue-en/sale': '/eue-en/main-sale',
  '/uee-en/sale': '/uee-en/main-sale',
  '/de-it/saldi-ufficiali': '/de-it/saldi',
  '/ch-it/saldi-ufficiali': '/ch-it/saldi',
  '/ca-it/saldi-ufficiali': '/ca-it/saldi',
  '/au-it/saldi-ufficiali': '/au-it/saldi',
  '/hk-it/saldi-ufficiali': '/hk-it/saldi',
  '/jp-it/saldi-ufficiali': '/jp-it/saldi',
  '/cn-it/saldi-ufficiali': '/cn-it/saldi',
  '/kr-it/saldi-ufficiali': '/kr-it/saldi',
  '/mo-it/saldi-ufficiali': '/mo-it/saldi',
  '/sg-it/saldi-ufficiali': '/sg-it/saldi',
  '/ae-it/saldi-ufficiali': '/ae-it/saldi',
  '/kw-it/saldi-ufficiali': '/kw-it/saldi',
  '/qa-it/saldi-ufficiali': '/qa-it/saldi',
  '/row-it/saldi-ufficiali': '/row-it/saldi',
  '/gb-it/saldi-ufficiali': '/gb-it/saldi',
  '/us-it/saldi-ufficiali': '/us-it/saldi',
  '/de-en/main-sale': '/de-en/sale',
  '/ch-en/main-sale': '/ch-en/sale',
  '/ca-en/main-sale': '/ca-en/sale',
  '/au-en/main-sale': '/au-en/sale',
  '/hk-en/main-sale': '/hk-en/sale',
  '/jp-en/main-sale': '/jp-en/sale',
  '/cn-en/main-sale': '/cn-en/sale',
  '/kr-en/main-sale': '/kr-en/sale',
  '/mo-en/main-sale': '/mo-en/sale',
  '/sg-en/main-sale': '/sg-en/sale',
  '/ae-en/main-sale': '/ae-en/sale',
  '/kw-en/main-sale': '/kw-en/sale',
  '/qa-en/main-sale': '/qa-en/sale',
  '/row-en/main-sale': '/row-en/sale',
  '/gb-en/main-sale': '/gb-en/sale',
  '/us-en/main-sale': '/us-en/sale',
  '/bh-en/main-sale': '/bh-en/sale',
};

export const switchSaleCanonicals = (canonicalPath: string, route: ComputedRef<Route>) => {
  if (!canonicalPath?.includes('sale') && !canonicalPath?.includes('saldi')) {
    return canonicalPath;
  }

  let currentPath = route.value?.path;
  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1);
  }
  if (canonicalsMapping[currentPath]) {
    return canonicalsMapping[currentPath];
  }
  return currentPath;
};
